import { useState, useEffect, useRef } from "react";

import moment from "moment";
import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Card,
  Frame,
  Icon,
  Popover,
  Pagination,
  Select,
  DatePicker,
  TextField,
  Toast,
  DataTable,
  Link,
  Spinner,
} from "@shopify/polaris";
import { SearchMinor, CalendarMinor } from "@shopify/polaris-icons";

import { Modal } from "react-bootstrap";
import ImageZoomInOut from "./ImageZoomInOut";
import PDFViewer from "./PDFViewer";

import {
  ALL_SUBMISSIONS_SORT_FIELDS,
  PAGE_SIZE,
  BANNER_STATUS,
  SUBMISSION_STATUS,
  FILE_TYPE
} from "./constants";

function AllSubmissions() {
  const [modalOpen, setModalOpen] = useState(false);

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [showModalSpinner, setShowModalSpinner] = useState(false);

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();
  const [submission, setSubmission] = useState({});
  const [submissionList, setSubmissionList] = useState([]);

  const handleModelOpen = (event, submissionDetails) => {
    event.preventDefault();
    const id = submissionDetails["id"];

    setModalOpen(true);
    setSubmission({});
    setShowModalSpinner(true);

    getSubmissionDetails(id).then(async (res) => {
      setSubmission(res);
      setShowModalSpinner(false);
      setModalOpen(true);
    });
  };

  const getSubmissionDetails = async (id) => {
    const bannerTitle = "Submission Details";

    const url = `/api/promo/submission/${id}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, submission } = data;
        setShowSpinner(false);
        if (!status) {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error getting in submission details`
          );
        }
        return submission;
      } else {
        setShowSpinner(false);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error getting in submission details`
        );
      }
    } catch (error) {
      setShowSpinner(false);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting submission details`
      );
    }
  };

  const submissionListMapping = (list) => {
    return list.map((ele) => {
      const link = (
        <Link
          removeUnderline
          onClick={(e) => {
            handleModelOpen(e, ele);
          }}
        >
          {ele.email}
        </Link>
      );

      let reviewer = ele.reviewer;
      let modifiedDate = "";

      if (ele.approver) {
        reviewer = ele.approver;
        modifiedDate = moment(ele.aprovedDate).utc().format("MM/DD h:mm a");
      }

      if (ele.denier) {
        reviewer = ele.denier;
        modifiedDate = moment(ele.deniedDate).utc().format("MM/DD h:mm a");
      }

      let orderName = "";
      let fulfillmentStatus = "";
      if (ele.orderName) {
        orderName = ele.orderName;
      }
      if (ele.fulfillmentStatus) {
        fulfillmentStatus = ele.fulfillmentStatus;
      }

      return [
        link,
        moment(ele.createdDate).utc().format("MM/DD h:mm a"),
        ele.shippingAddress1,
        ele.shippingAddress2,
        ele.addressUsed,
        ele.status,
        reviewer,
        modifiedDate,
        orderName,
        fulfillmentStatus,
      ];
    });
  };

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const [query, setQuery] = useState({
    search: "",
    sortField: "Created Date, Old to New",
    sortOrder: "asc",
    page: 1,
    pageSize: 20,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      if (key === "submissionEmail") {
        params.push(`search=${encodeURIComponent(query[key])}`);
      } else {
        params.push(`${key}=${encodeURIComponent(query[key])}`);
      }
    }

    return params;
  };

  const getPendingSubmissions = async () => {
    const bannerTitle = "Submissions List";

    const params = queryParams();
    const url = `/api/promo/submissions/list?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total } = data;
        setShowSpinner(false);
        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading submission list`
          );
        }

        setHasPrevious(hasPrevious);
        setHasNext(hasNext);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setSubmissionList([]);
          return;
        }

        const submissionList = submissionListMapping(list);
        setSubmissionList(submissionList);
      } else {
        setSubmissionList([]);
        setShowSpinner(false);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error querying submission list`
        );
      }
    } catch (error) {
      setShowSpinner(false);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting submissions list`
      );
    }
  };

  const searchChanged = (value) => {
    setQuery({
      ...query,
      search: value,
    });
  };

  const columnContentTypes = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];

  const headings = [
    "Email",
    "Submission Date",
    "Address",
    " ",
    "Address Used",
    "Status",
    "Assignee",
    "Approved/Denied Date",
    "Order",
    "Fulfillment Status",
  ];

  const makeRequest = async (path) => {
    const bannerTitle = "Filters";

    try {
      const url = `/api/${path}`;
      return axios.get(url);
    } catch (error) {
      setShowSpinner(false);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting filters`
      );
    }
  };

  const getFilterData = async () => {
    const filterUrls = [
      {
        name: "ages",
        url: "promo/ages/list",
      },
      {
        name: "countries",
        url: "promo/countries/list",
      },
      {
        name: "reviewers",
        url: "promo/reviewers/list",
      },
      {
        name: "genders",
        url: "promo/genders/list",
      },
      {
        name: "reasonLists",
        url: "promo/deny/list",
      },
      {
        name: "purchasingMethods",
        url: "promo/purchasingMethods/list",
      },
      {
        name: "retailers",
        url: "promo/retailers/list",
      },
      {
        name: "states",
        url: "promo/states/list/1",
      },
      {
        name: "submissionStatus",
        url: "promo/status/list",
      },
      {
        name: "fulfillmentStatus",
        url: "promo/fulfillmentStatus/list",
      },
    ];

    let promiseArray = [];
    filterUrls.map((ele) => {
      promiseArray.push(makeRequest(ele["url"]));
    });

    const responseData = await Promise.all(promiseArray);
    for (let index = 0; index < filterUrls.length; index++) {
      const element = responseData[index];
      filterUrls[index]["data"] = element["data"]["list"];
    }
    return filterUrls;
  };

  const exportAllSubmissions = async () => {
    const bannerTitle = "Export Submissions";

    const params = queryParams();
    let newParams = [];

    params.map((ele) => {
      const keyName = ele.split("=")[0];
      if (
        keyName == "sortOrder" ||
        keyName == "page" ||
        keyName == "sortOrder" ||
        keyName == "sortField" ||
        keyName == "pageSize"
      ) {
        console.log(keyName, "--");
      } else {
        newParams.push(ele);
      }
    });

    const url = `/api/promo/submissions/export/list?${newParams.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error in exporting all submission`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error in exporting all submission`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error exporting submissions`
      );
    }
  };

  const [ageOptions, setAgeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [purchaseMethodOptions, setPurchaseMethodOptions] = useState([]);
  const [retailersOptions, setRetailersOptions] = useState([]);
  const [submissionStatusOptions, setSubmissionStatusOptions] = useState([]);
  const [reviewrOptions, setReviewrOptions] = useState([]);
  const [approverOptions, setApproverOptions] = useState([]);
  const [denierOptions, setDenierOptions] = useState([]);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [fulfilmentStatusOptions, setFulfillmentStatusOptions] = useState([]);
  const [purchaseStateOptions, setPurchaseStateOptions] = useState([]);

  const [selectedAge, setSelectedAge] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPurchaseState, setSelectedPurchaseState] = useState("");

  const [selectedGender, setSelectedGender] = useState("");
  const [selectedPurchaseMethod, setSelectedPurchaseMethod] = useState("");
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [selectedSubmissionStatus, setSelectedSubmissionStatus] = useState("");

  const [selectedReviewer, setSelectedReviewer] = useState("");
  const [selectedApprover, setSelectedApprover] = useState("");
  const [selectedDenier, setSelectedDenier] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedFulfillmentStatus, setSelectedFulfillmentStatus] = useState(
    ""
  );

  const [searchForm, setSearchForm] = useState(false);

  const toggleSearchForm = () => setSearchForm((searchForm) => !searchForm);

  const [startDateVisible, setStartDateVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: selectedStartDate.getMonth(),
    startYear: selectedStartDate.getFullYear(),
  });

  const [submissionStartDateVisible, setSubmissionStartDateVisible] = useState(
    false
  );
  const [selectedSubmissionDate, setSelectedSubmissionStartDate] = useState(
    new Date()
  );
  const [
    { submissionMonth, submissionYear },
    setSubmissionStartDate,
  ] = useState({
    submissionMonth: selectedSubmissionDate.getMonth(),
    submissionYear: selectedSubmissionDate.getFullYear(),
  });

  const formattedValue = (selectedDate) =>
    selectedDate.toISOString().slice(0, 10);

  function startDateOnClose({ relatedTarget }) {
    setStartDateVisible(false);
  }

  function startDateMonthChanged(month, year) {
    setStartDate({ startMonth: month, startYear: year });
  }

  function startDateChanged({ end: newSelectedDate }) {
    setSelectedStartDate(newSelectedDate);
    setStartDateVisible(false);

    setQuery({
      ...query,
      purchaseDate: moment(newSelectedDate).utc().format("MM/DD/YYYY"),
      page: 1,
    });
  }

  function submissionStartDateOnClose({ relatedTarget }) {
    setSubmissionStartDateVisible(false);
  }

  function submissionStartDateMonthChanged(month, year) {
    setSubmissionStartDate({ startMonth: month, startYear: year });
  }

  function submissionDateChanged({ end: newSelectedDate }) {
    setSelectedSubmissionStartDate(newSelectedDate);
    setSubmissionStartDateVisible(false);

    setQuery({
      ...query,
      createdDate: moment(newSelectedDate).utc().format("MM/DD/YYYY"),
      page: 1,
    });
  }

  const textFieldChanged = (field, value) => {
    setQuery({
      ...query,
      page: 1,
      [field]: value,
    });
  };

  const createListOptions = (label, array, key) => {
    let allOptions = [
      {
        label: `Select ${label}`,
        value: "",
      },
    ];
    array.forEach((ele) => {
      const labelValue = ele[key];
      allOptions.push({
        label: labelValue,
        value: ele["id"].toString(),
      });
    });
    return allOptions;
  };

  useEffect(async () => {
    const filterData = await getFilterData();
	console.log('...filterData', {filterData})

    for (let i = 0; i < filterData.length; i++) {
      const element = filterData[i]["name"];
      const data = filterData[i]["data"];
      switch (element) {
        case "ages":
          const ageOptions = createListOptions("Age Range", data, "age");
          setAgeOptions(ageOptions);

          break;
        case "countries":
          const countryOptions = createListOptions(
            "Shipping Country",
            data,
            "country"
          );
          setCountryOptions(countryOptions);
          break;
        case "genders":
          const genderOptions = createListOptions("Gender", data, "gender");
          setGenderOptions(genderOptions);
          break;
        case "reviewers":
          const reviewerOptions = createListOptions("Reviewer", data, "email");
          const approverOptions = createListOptions("Approver", data, "email");
          const denierOptions = createListOptions("Denier", data, "email");
          setReviewrOptions(reviewerOptions);
          setApproverOptions(approverOptions);
          setDenierOptions(denierOptions);
          break;
        case "reasonLists":
          const reasonListOptions = createListOptions(
            "Deny Reason",
            data,
            "reason"
          );
          setReasonOptions(reasonListOptions);
          break;
        case "purchasingMethods":
          const purchaseMethodOptions = createListOptions(
            "Purchase Method",
            data,
            "method"
          );
          setPurchaseMethodOptions(purchaseMethodOptions);
          break;
        case "retailers":
          const retailersOptions = createListOptions(
            "Retailer",
            data,
            "retailer"
          );
          setRetailersOptions(retailersOptions);
          break;
        case "states":
          const stateOptions = createListOptions(
            "Shipping State",
            data,
            "state"
          );
          setStateOptions(stateOptions);

          const purchaseStateOptions = createListOptions(
            "Purchase State",
            data,
            "state"
          );
          setPurchaseStateOptions(purchaseStateOptions);

          break;
        case "submissionStatus":
          const submissionStatusOptions = createListOptions(
            "Status",
            data,
            "status"
          );
          setSubmissionStatusOptions(submissionStatusOptions);
          break;
        case "fulfillmentStatus":
          const fulfillmentStatusOptions = createListOptions(
            "Fulfillment",
            data,
            "status"
          );
          setFulfillmentStatusOptions(fulfillmentStatusOptions);
          break;
        default:
          break;
      }
    }
  }, []);

  const selectionChanged = (option, key, filterName) => {
    setQuery({
      ...query,
      [key]: option,
    });

    switch (filterName) {
      case "age":
        setSelectedAge(option);
        break;
      case "shippingCountry":
        setSelectedCountry(option);
        break;
      case "shippingState":
        setSelectedState(option);
        break;
      case "purchaseState":
        setSelectedPurchaseState(option);
        break;
      case "gender":
        setSelectedGender(option);
        break;
      case "purchasingMethod":
        setSelectedPurchaseMethod(option);
        break;
      case "retailer":
        setSelectedRetailer(option);
        break;
      case "status":
        setSelectedSubmissionStatus(option);
        break;
      case "denyReason":
        setSelectedReason(option);
        break;
      case "reviewer":
        setSelectedReviewer(option);
        break;
      case "approver":
        setSelectedApprover(option);
        break;
      case "denier":
        setSelectedDenier(option);
        break;
      case "fulfillmentStatus":
        setSelectedFulfillmentStatus(option);
        break;

      default:
        break;
    }
  };

  const clearSubmissionMail = () => {
    setQuery({
      ...query,
      submissionEmail: "",
    });
  };

  const clearSearchRecords = () => {
    setQuery({
      ...query,
      search: "",
    });
  };

  const clearAllFilter = () => {
    setSelectedGender("");
    setSelectedAge("");
    setSelectedPurchaseMethod("");
    setSelectedRetailer("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedSubmissionStatus("");
    setSelectedReviewer("");
    setSelectedApprover("");
    setSelectedDenier("");
    setSelectedReason("");
    setSelectedFulfillmentStatus("");

    setQuery({
      search: "",
      sortField: "Created Date, Old to New",
      sortOrder: "asc",
      page: 1,
      pageSize: 20,
    });
  };

  useEffect(() => {
    debounce(getPendingSubmissions, debounceTimeout);
  }, [query]);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  //   const search = () => getPendingSubmissions();

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState("5");

  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [selectedPageSize, setPageSize] = useState("2");

  useEffect(() => {
    const list = PAGE_SIZE.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setPageSizeOptions(list);
  }, []);

  const pageSizeChanged = (option) => {
    setPageSize(option);

    const found = PAGE_SIZE.find((item) => item.id === parseInt(option));

    setQuery({
      ...query,
      pageSize: found.size,
    });
  };

  useEffect(() => {
    const list = ALL_SUBMISSIONS_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setSortOptions(list);

    const found = ALL_SUBMISSIONS_SORT_FIELDS.find(
      (item) => item.field === query.sortField && item.order === query.sortOrder
    );

    if (found) {
      setSelectedSort(found.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = ALL_SUBMISSIONS_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "330px" }}>
          <TextField
            placeholder="Search Submissions"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            onFocus={clearSubmissionMail}
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>

        <div style={{ marginLeft: 5, minWidth: "150px" }}>
          <Button primary fullWidth onClick={toggleSearchForm}>
            Advanced Filters
          </Button>
        </div>
        <div style={{ marginLeft: 5, minWidth: "150px" }}>
          <Button primary fullWidth onClick={exportAllSubmissions}>
            Export
          </Button>
        </div>

        <div style={{ width: "100%" }}></div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Page Size:</label>
          </div>
          <Select
            label=""
            placeholder="Page Size"
            options={pageSizeOptions}
            onChange={pageSizeChanged}
            value={selectedPageSize}
          />
        </div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Sort By:</label>
          </div>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {searchForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <Card>
            <div
              style={{
                padding: "10px",
              }}
            >
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                Advanced Filter
              </label>

              <div style={{ display: "flex", padding: "10px" }}>
                <div style={{ width: "20%", paddingRight: "20px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <label>Email</label>
                    <TextField
                      value={query.submissionEmail}
                      onChange={(e) => textFieldChanged("submissionEmail", e)}
                      label=""
                      type="text"
                      onFocus={clearSearchRecords}
                      autoComplete="off"
                      placeholder="Enter Email"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Gender</label>
                    <Select
                      label=""
                      options={genderOptions}
                      onChange={(e) => selectionChanged(e, "gender", "gender")}
                      value={selectedGender}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Age Range</label>
                    <Select
                      label=""
                      options={ageOptions}
                      onChange={(e) => selectionChanged(e, "age", "age")}
                      value={selectedAge}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>ReceiptId</label>
                    <TextField
                      value={query.receiptId}
                      onChange={(e) => textFieldChanged("receiptId", e)}
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter ReceiptId"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Same ReceiptId found</label>
                    <TextField
                      value={query.receiptIdUsed}
                      onChange={(e) => textFieldChanged("receiptIdUsed", e)}
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter ReceiptId Used"
                    />
                  </div>
                </div>

                <div style={{ width: "20%", paddingRight: "20px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <label>Purchasing Method</label>
                    <Select
                      label=""
                      options={purchaseMethodOptions}
                      onChange={(e) =>
                        selectionChanged(
                          e,
                          "purchasingMethod",
                          "purchasingMethod"
                        )
                      }
                      value={selectedPurchaseMethod}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Purchase date</label>
                    <Popover
                      active={startDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={startDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label=""
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedStartDate)}
                          onFocus={() => setStartDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={startMonth}
                          year={startYear}
                          selected={selectedStartDate}
                          onMonthChange={startDateMonthChanged}
                          onChange={startDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Model Number</label>
                    <TextField
                      value={query.modelNumber}
                      onChange={(e) => textFieldChanged("modelNumber", e)}
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Model Number"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Retailer</label>
                    <Select
                      label=""
                      options={retailersOptions}
                      onChange={(e) =>
                        selectionChanged(e, "retailer", "retailer")
                      }
                      value={selectedRetailer}
                    />
                  </div>

                  <div>
                    <label>State Of Purchase</label>
                    <Select
                      label=""
                      options={purchaseStateOptions}
                      onChange={(e) =>
                        selectionChanged(e, "purchaseState", "purchaseState")
                      }
                      value={selectedPurchaseState}
                    />
                  </div>
                </div>

                <div style={{ width: "20%", paddingRight: "20px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <label>Shipping Address</label>
                    <TextField
                      value={query.shippingAddressLine}
                      onChange={(e) =>
                        textFieldChanged("shippingAddressLine", e)
                      }
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Shipping Address"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>City</label>
                    <TextField
                      value={query.shippingCity}
                      onChange={(e) => textFieldChanged("shippingCity", e)}
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Shipping City"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Country</label>
                    <Select
                      label=""
                      options={countryOptions}
                      onChange={(e) =>
                        selectionChanged(
                          e,
                          "shippingCountry",
                          "shippingCountry"
                        )
                      }
                      value={selectedCountry}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>State</label>
                    <Select
                      label=""
                      options={stateOptions}
                      onChange={(e) =>
                        selectionChanged(e, "shippingState", "shippingState")
                      }
                      value={selectedState}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Postal Code</label>
                    <TextField
                      value={query.shippingPostalCode}
                      onChange={(e) =>
                        textFieldChanged("shippingPostalCode", e)
                      }
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Shipping Postal Code"
                    />
                  </div>
                </div>

                <div style={{ width: "20%", paddingRight: "20px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <label>Status</label>
                    <Select
                      label=""
                      options={submissionStatusOptions}
                      onChange={(e) => selectionChanged(e, "status", "status")}
                      value={selectedSubmissionStatus}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Assignee</label>
                    <Select
                      label=""
                      options={reviewrOptions}
                      onChange={(e) =>
                        selectionChanged(e, "reviewer", "reviewer")
                      }
                      value={selectedReviewer}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Approver</label>
                    <Select
                      label=""
                      options={approverOptions}
                      onChange={(e) =>
                        selectionChanged(e, "approver", "approver")
                      }
                      value={selectedApprover}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Denier</label>
                    <Select
                      label=""
                      options={denierOptions}
                      onChange={(e) => selectionChanged(e, "denier", "denier")}
                      value={selectedDenier}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Reason</label>
                    <Select
                      label=""
                      options={reasonOptions}
                      onChange={(e) =>
                        selectionChanged(e, "denyReason", "denyReason")
                      }
                      value={selectedReason}
                    />
                  </div>
                </div>

                <div style={{ width: "20%", paddingLeft: "10px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <label>Order Number</label>
                    <TextField
                      value={query.orderNumber}
                      onChange={(e) => textFieldChanged("orderNumber", e)}
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Order Number"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Fullfillment</label>
                    <Select
                      label=""
                      options={fulfilmentStatusOptions}
                      onChange={(e) =>
                        selectionChanged(
                          e,
                          "fulfillmentStatus",
                          "fulfillmentStatus"
                        )
                      }
                      value={selectedFulfillmentStatus}
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Same Shipping Address found</label>
                    <TextField
                      value={query.shippingAddressUsed}
                      onChange={(e) =>
                        textFieldChanged("shippingAddressUsed", e)
                      }
                      label=""
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Address Used"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <label>Submission date</label>
                    <Popover
                      active={submissionStartDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={submissionStartDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label=""
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedSubmissionDate)}
                          onFocus={() => setSubmissionStartDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={submissionMonth}
                          year={submissionYear}
                          selected={selectedSubmissionDate}
                          onMonthChange={submissionStartDateMonthChanged}
                          onChange={submissionDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>

                  <div style={{ height: "10%", marginTop: "23px" }}>
                    <Button primary fullWidth onClick={clearAllFilter}>
                      {" "}
                      Clear All
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      {showSpinner && (
        <div className="loader">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      )}

      {submissionList && (
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={submissionList}
        />
      )}

      {modalOpen && (
        <ModalWindow
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          submission={submission}
          showModalSpinner={showModalSpinner}
          setShowModalSpinner={setShowModalSpinner}
        />
      )}
      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

function getFileType(base64String) {

  switch (base64String.charAt(0)) {
    case "/":
      return FILE_TYPE.JPG;
    case "i":
      return FILE_TYPE.PNG;
    case "J":
      return FILE_TYPE.PDF;
    default:
      return FILE_TYPE.UNKNOWN;
  }
}

function ModalWindow({
  modalOpen,
  setModalOpen,
  submission,
  showModalSpinner,
  setShowModalSpinner,
}) {
  let isPDFBase64, base64;

  if (!showModalSpinner && submission) {

    base64 = submission.receiptBase64

    const fileType = getFileType(base64);

    base64 = `data:image/${fileType};base64,${base64}`;

    if (fileType == 'pdf') {
      isPDFBase64 = true;
    } else if (fileType == 'unknown') {
      base64 = `data:image/png;base64,${base64}`;
    }
  }

  return (
    <Modal
      id="submission-modal"
      show={modalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={(e) => setModalOpen(false)}
    >
      {showModalSpinner ? (
        <div style={{ height: "750px", width: "750px" }}>
          <Modal.Body className="modalPopupWithoutData">
            <div>
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </div>
          </Modal.Body>
        </div>
      ) : (
        <div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {submission.id} - {submission.displayName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalContent moalBodyColor">
            <div className="detail-view-modal">
              <div className="detail-section">
                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Customer
                  </h3>
                  <div className="detail-section--field">
                    <div class="dataValue">{submission.displayName}</div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">{submission.email}</div>
                  </div>
                </div>

                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Purchase
                  </h3>

                  <div className="detail-section--field">
                    <div className="dataLabel">Method</div>
                    <div class="dataValue">{submission.purchasingMethod}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Purchase Date</div>
                    <div class="dataValue">
                      {moment(submission.purchaseDate)
                        .utc()
                        .format("MM-DD-YYYY")}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">ReceiptId</div>
                    <div class="dataValue">{submission.receiptId}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Model Number</div>
                    <div class="dataValue">{submission.modelNumber}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Retailer</div>
                    <div class="dataValue">{submission.retailer}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">City, State of Purchase</div>
                    <div class="dataValue">
                      {submission.purchaseCity}, {submission.purchaseState}
                    </div>
                  </div>
                </div>

                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingFirstName}{" "}
                      {submission.shippingLastName}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingAddress1},{" "}
                      {submission.shippingAddress2}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingCity} {submission.shippingState}{" "}
                      {submission.shippingPostalCode}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">{submission.shippingCountry}</div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      <a>
                        +1{" "}
                        {submission.shippingPhone.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        )}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="detail-section--box">
                  {submission.status === SUBMISSION_STATUS.UN_ASSIGNED && (
                    <>
                      <div className="detail-section--field">
                        <div className="dataLabel">Status</div>
                        <div class="dataValue">{submission.status}</div>
                      </div>
                    </>
                  )}

                  {(submission.status === SUBMISSION_STATUS.ASSIGNED ||
                    submission.status === SUBMISSION_STATUS.IN_REVIEW) && (
                      <>
                        <div className="detail-section--field">
                          <div className="dataLabel">Status</div>
                          <div class="dataValue">{submission.status}</div>
                        </div>

                        <div className="detail-section--field">
                          <div className="dataLabel">Assignee</div>
                          <div class="dataValue">{submission.reviewer}</div>
                        </div>
                      </>
                    )}

                  {submission.status === SUBMISSION_STATUS.DENIED && (
                    <>
                      <div className="detail-section--field">
                        <div className="dataLabel">Status</div>
                        <div class="dataValue" style={{ color: "red" }}>
                          {submission.status}
                        </div>
                      </div>

                      <div className="detail-section--field">
                        <div className="dataLabel">Deny Reason</div>
                        <div class="dataValue" style={{ color: "red" }}>
                          {submission.denyReason}
                        </div>
                      </div>
                    </>
                  )}

                  {(submission.status === SUBMISSION_STATUS.APPROVED ||
                    submission.status === SUBMISSION_STATUS.ORDER_CREATED) && (
                      <>
                        <div className="detail-section--field">
                          <div className="dataLabel">Status</div>
                          <div class="dataValue">{submission.status}</div>
                        </div>

                        <div className="detail-section--field">
                          <div className="dataLabel">Order number</div>
                          <div class="dataValue">{submission.orderName}</div>
                        </div>
                      </>
                    )}

                  {(submission.status === SUBMISSION_STATUS.SHIPPED ||
                    submission.status === SUBMISSION_STATUS.DELIVERED) && (
                      <>
                        <div className="detail-section--field">
                          <div className="dataLabel">Status</div>
                          <div class="dataValue">{submission.status}</div>
                        </div>

                      <div className="detail-section--field">
                        <div className="dataLabel">Order number</div>
                        <div class="dataValue">{submission.orderName}</div>
                      </div>

                        <div className="detail-section--field">
                          <div className="dataLabel">Tracking number</div>
                          <div class="dataValue">{submission.trackingNumber}</div>
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div class="image-section">
                {isPDFBase64 ? (
                  <PDFViewer name={submission.email} PDFBase64={base64} />
                ) : (
                  <ImageZoomInOut
                    imageBase64={base64}
                    name={submission.email}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );
}

export default AllSubmissions;
